function setEqualHeight() {
    $(".grid-element-content-header-image").matchHeight();
    $(".grid-element-title").matchHeight();
    $(".productshortdesc").matchHeight();
    $(".productshortattributes").matchHeight();
    $(".prices-small").matchHeight();
    $(".product-grid-product .stock-container").matchHeight();
    $(".product-grid-product .stock-container").matchHeight();
    $(".nakup").matchHeight();
    $(".product-detail-attribute-value-list-2 > ul > li").matchHeight();
    $(".grid-element-content-related-header-image").matchHeight();
}
$(window).on("load", function() {
    setEqualHeight();
});
$(window).on("resize", function() {
    // setEqualHeight();
    $.fn.matchHeight._update();
});

/*
$(window).load(function() {
    var trgovinaHeight = $("#prva-banner").height()-30;
	equalheight(".grid-element-content-header-image");
    equalheight(".grid-element-title");
    equalheight(".productshortdesc");
    equalheight(".productshortattributes");
    equalheight(".prices-small");
    equalheight(".nakup");
    equalheight(".product-detail-attribute-value-list-2 > ul > li");
    equalheight(".grid-element-content-related-header-image");

    // $("#prva-servis-trgovina").outerHeight(trgovinaHeight);
    //$("#prva-trgovina-wrapper").outerHeight(trgovinaHeight/2);
    //$("#prva-servis-wrapper").outerHeight(trgovinaHeight/2);
});
*/

$('#main-menu').bind('show.smapi', function(e, menu) {
    var maxHeight,
        skelBreakpoints = skel.vars.stateId;
    if (skelBreakpoints.search("/medium") === -1 && $(menu).hasClass("mega-menu")) {
        equalheight(".mega-menu-item");
    }
});

$('#main-menu-state').change(function(e) {
    var $menu = $('#main-menu');
    var maxHeight,
        skelBreakpoints = skel.vars.stateId;
    if (this.checked) {
        $menu.hide().slideDown(250, function() {
            $menu.css('display', '');
            if (skelBreakpoints.search("/medium") > -1) {
                maxHeight = 0.99 * ($(window).height() - ($("#main-menu").offset().top - $(window).scrollTop()));
                $('#main-menu').css("max-height", maxHeight + "px");
                if ($('#main-menu').outerHeight() > maxHeight) {
                    $('#main-menu').css("height", maxHeight + "px");
                }
            }
        });
    } else {
        $menu.show().slideUp(250, function() {
            $menu.css('display', '');
            $('#main-menu').css("max-height", "");
            $('#main-menu').css("height", "");
        });
    }
});

$(window).bind('scroll', function() {
    if ($(window).scrollTop() > 139) {
        $('#nav-wrapper').addClass('fixed');
        $("body").addClass('fixed-header');
    } else {
        $('#nav-wrapper').removeClass('fixed');
        $("body").removeClass('fixed-header');
    }
});
/*
var $animation_elements = $(".animation-element"),
$window = $(window);
$in_view_timeout = 500;

function set_in_view(el, i, window_height, window_top_position, window_bottom_position) {
	if (!isNumeric(i)) {
		i = 1;
	};
	var $el = $(this),
	element_height = el.outerHeight(),
	element_top_position = el.offset().top,
	element_bottom_position = (element_top_position + element_height);
	if ((element_bottom_position >= window_top_position) && (element_top_position <= window_bottom_position)) {
		i++;
		setTimeout(function () {
			el.addClass("in-view");
		},
			i * $in_view_timeout);
	} else {
		el.removeClass("in-view");
	}
	return i;
};

function check_if_in_view() {
	var window_height = $window.height(),
	window_top_position = $window.scrollTop(),
	window_bottom_position = window_top_position + window_height,
	i = 0,
	el;

	//$.each($animation_elements, function () {
	//el = $(this);
	//i = set_in_view(el,i,window_height,window_top_position,window_bottom_position);
	//});
	el = $("#o-nas-1");
	i = set_in_view(el, i, window_height, window_top_position, window_bottom_position);
	el = $("#o-nas-2");
	i = set_in_view(el, i, window_height, window_top_position, window_bottom_position);
	el = $("#o-nas-3");
	i = set_in_view(el, i, window_height, window_top_position, window_bottom_position);
};

$window.on("scroll resize", check_if_in_view);
$window.trigger("scroll");
*/
